import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  IconButton,
  colors
} from '@mui/material';
import React, { useEffect, useState, Fragment } from 'react';
import Iconify from 'src/components/Iconify';
import { BULK_START_TYPE, contestTypes, GoogleMapsApiKey, QUESTION_TO_SHOW_CONSTANT } from '../../../contants/contants';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Page from 'src/components/Page';
import AsyncSelect from 'react-select/async';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import Select from 'react-select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { ROUTES } from 'src/router/routes';
import BackButton from 'src/components/backButton';
import UploadImage from 'src/components/uploadImage';
import Autocomplete from 'react-google-autocomplete';
import { handleRewardType } from '../../../utils/helper';
import { IMAGE_MAX_SIZE } from '../../../contants/contants';
import moment from 'moment';
import { addEditContestBulkSchema } from 'src/utils/validationSchemas';
import { confirmAlert } from 'react-confirm-alert';

const labelStyle = {
  fontSize: '12px'
};

const placePickerStyles = {
  width: '100%',
  height: '52px',
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid hsl(0, 0%, 80%)',
};

const emptyRewardData = {
  positions: [],
  rewardId: '',
  expireDays: '',
  redeemTime: '',
  winningPoints: '',
};

const initialValues = {
  status: 1, // 1- draft, 2- final 
  contestType: 1,
  partnerId: '',
  homeTeamId: '',
  awayTeamId: '',
  locations: [''],
  name: '',
  weekName: '',
  maxDays: '',
  startType: '',
  isAgeNeeded: false,
  overview: [''],
  howItWorks: [''],
  termsLink: 'https://www.playshotcaller.com/terms-of-service',
  rulesLink: 'https://www.playshotcaller.com/',
  startDateTime: moment(),
  emailSubject: '',
  emailBodyContent: '',
  sendNotifications: false,
  postOnTwitter: false,
  sendContestUserNotifications: false,
  excludePartnersFromAllNotification: [],

  imageLink: '',
  emailImage: '',
  contestBackgroundImage: '',
  challengeShareCard: '',
  beatPicksShareCard: '',
  twitterShareCard: '',

  numberOfPositions: 0,
  rewardData: [emptyRewardData],

  category: '',
  brand: '',
  topic: '',
  subtopic: '',
  questionsCount: 5,
  quartersCount: 5,

};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 56,
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const imageType = {
  contestImage: 1,
  contestBackgroundImage: 2,
  emailImage: 3,
  challengeShareCard: 4,
  beatPicksShareCard: 5,
  twitterShareCard: 6,
};

const QUESTION_FORM_FIELDS = [
  { label: 'Category', identifier: 'category', type: 'text', gridSize: 3 },
  { label: 'Brand', identifier: 'brand', type: 'text', gridSize: 3, placeholder: 'Comma seperated' },
  { label: 'Topic', identifier: 'topic', type: 'text', gridSize: 3 },
  { label: 'Sub Topic', identifier: 'subtopic', type: 'text', gridSize: 3 },
  { label: '# Questions each quarter', identifier: 'questionsCount', type: 'number', gridSize: 3 },
  { label: '# Quarters (with pre)', identifier: 'quartersCount', type: 'number', gridSize: 3 },
];

const IMAGE_UPLOAD_FIELDS = [
  { title: "Contest Image", fieldName: "imageLink", imageType: imageType.contestImage },
  { title: "Contest Background Image", fieldName: "contestBackgroundImage", imageType: imageType.contestBackgroundImage },
  { title: "Email Image", fieldName: "emailImage", imageType: imageType.emailImage },
  { title: "Challenge Share Card", fieldName: "challengeShareCard", imageType: imageType.challengeShareCard },
  { title: "Beat Picks Share Card", fieldName: "beatPicksShareCard", imageType: imageType.beatPicksShareCard },
  { title: "Twitter Share Card", fieldName: "twitterShareCard", imageType: imageType.twitterShareCard },
];

const AddEditContestBulkCreate = ({ isEdit }) => {
  const [contestData, setContestData] = useState();

  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageLoading, setPageLoading] = useState(false);
  const cloneId = searchParams.get('clone');

  const [locationError, setLocationError] = useState(false);
  const [removeLocations, setRemoveLocations] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);

  useEffect(() => {
    if (id || cloneId) {
      getData(id);
    }
  }, [id]);

  const getData = (id) => {
    const params = { id };
    setPageLoading(true);
    listApi(APIs.LIST_CONTESTS_BULK, params).then(async (response) => {
      const obj = {
        ...response?.data
      };
      obj.startDateTime = new Date(obj?.startDateTime);
      if (obj.partnerData)
        obj.partnerId = { label: obj.partnerData.name, value: obj.partnerData.id };

      obj.homeTeamId = { label: createLabelteam(obj?.homeTeamData), value: obj?.homeTeamData?.id };
      obj.awayTeamId = { label: createLabelteam(obj?.awayTeamData), value: obj?.awayTeamData?.id };

      obj.excludePartnersFromAllNotification = obj?.excludePartnersFromAllNotification?.map((item) => ({
        label: `${item?.name}`,
        value: item.id,
      }));

      if (!obj.rewardData) obj.rewardData = [emptyRewardData];
      else {
        obj.rewardData = obj?.rewardData?.map((item) => ({
          ...item,
          rewardId: {
            label: createRewardLabel(item.rewardId, handleRewardType(item?.rewardId?.type)),
            name: `${item?.rewardId?.title | item?.rewardId?.shortDescription | handleRewardType(item?.rewardId?.type) | item?.rewardId?.price}`,
            value: item?.rewardId?.id
          },
          positions: item?.positions?.map((pos) => ({ label: pos, value: pos })),
        }));
      }
      setContestData(obj);
    })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const handleFormSubmit = (payloadValues) => {

    if (payloadValues?.locations?.length && payloadValues?.locations[0]?.lat) {
      const locations = payloadValues?.locations?.map((item, index) => {
        item.radius = parseFloat(payloadValues?.radius);
        if (item?.id) {
          return item;
        } else {
          return {
            address: item?.formatted_address,
            placeId: item?.place_id,
            lat: item?.geometry?.location?.lat(),
            lng: item?.geometry?.location?.lng(),
            radius: parseFloat(payloadValues?.radius),
          };
        }
      });

      payloadValues.locations = locations;
    } else {
      delete payloadValues.locations;
    }

    payloadValues.homeTeamId = payloadValues?.homeTeamId?.value;
    payloadValues.awayTeamId = payloadValues?.awayTeamId?.value;
    payloadValues.partnerId = payloadValues?.partnerId?.value;

    if (payloadValues?.sendToAll && payloadValues?.excludePartners.length)
      payloadValues.excludePartners = payloadValues?.excludePartners.map((partner) => partner.value);
    else payloadValues.excludePartners = [];

    if (payloadValues?.rewardData?.length) {
      payloadValues.rewardData = payloadValues?.rewardData?.map((item) => {
        return {
          ...item,
          positions: item?.positions.map((position) => position.value),
          rewardId: item?.rewardId?.value,
        };
      })
    }

    if (id) payloadValues.id = id;

    addEditApi(APIs.ADD_EDIT_CONTEST_BULK, payloadValues).then((result) => {
      toast.success(`${id ? 'Updated' : 'Added'} Successfully`);
    })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        if(!id) navigate('/bulk-contests')
          else window.location.reload();
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: contestData || initialValues,
    validationSchema: addEditContestBulkSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, setErrors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    let option = [];
    for (let i = 1; i <= values.numberOfPositions; i++) {
      option.push({
        label: i,
        value: i,
      });
    }

    setPositionOptions(option);
  }, [values?.numberOfPositions]);

  const removeLocationHandler = (id) => {
    removeLocations.push(id);
    setRemoveLocations((removeLocations) => [...removeLocations]);
    setFieldValue('removeLocations', removeLocations);
  };

  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue })
      .then((result) => {
        const filter = result?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        resolve(filter);
      })
      .catch((error) => {
        resolve([]);
      });
  };

  function createRewardLabel(item, typealpha) {
    return (
      <div className="optionStyles">
        <img src={item?.imageLink} height="30px" width="30px" />
        <div className="selectText">
          {item?.title} | {item?.shortDescription} | {typealpha} | {item?.price}$
        </div>
      </div>
    );
  }

  const promiseReawrdDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_REWARD_TYPES, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => {
        let typealpha = handleRewardType(item?.type);
        let option = {
          label: createRewardLabel(item, typealpha),
          name: `${item?.title | item?.shortDescription | typealpha | item?.price}$`,
          value: item?.id,
        };
        return option;
      });
      resolve(filter);
    });
  };

  const loadPartners = debounce(partnerOptions, 300);
  const loadRewardTypes = debounce(promiseReawrdDataOptions, 300);

  const handleImageChange = (e, type) => {
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setErrors({ [type]: `Image size should be less than ${IMAGE_MAX_SIZE} MB` });
    } else {
      setFieldValue([type], e?.target?.files?.[0]);
    }
  };

  const handleAllImages = (item) => {
    const data = new FormData();
    data.append('file', values?.[item?.fieldName]);

    if (!data?.get('file')) {
      return;
    }

    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        setFieldValue(item?.fieldName, result?.profileImg);
      })
      .finally(() => {
        toast.success('Image uploaded successfully');
      });
  };

  function toggleSwitch1() {
    values.sendNotifications = !values.sendNotifications;
    if (values.sendNotifications) {
      values.sendContestUserNotifications = false; // If switch1 is turned on, switch2 is turned off
    }
  }

  function toggleSwitch2() {
    values.sendContestUserNotifications = !values.sendContestUserNotifications;
    if (values.sendContestUserNotifications) {
      values.sendNotifications = false; // If switch2 is turned on, switch1 is turned off
    }
  }

  function createLabelteam(item) {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar alt={item?.teamName} src={item?.image_url} />
        <Typography variant="subtitle2" noWrap>
          {item?.teamName}
        </Typography>
      </Stack>
    )
  };

  const promiseTeamDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_TEAMS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: createLabelteam(item),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const loadHomeTeamData = debounce(promiseTeamDataOptions, 300);
  const loadAwayTeamData = debounce(promiseTeamDataOptions, 300);

  return (
    <Page title="Bulk Contest" loading={pageLoading} >
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? 'Edit Bulk Contest' : 'Add Bulk Contest'}
              </Typography>
              <div style={{ display: 'flex' }}>
                <BackButton onClick={() => navigate(ROUTES.BULK_CONTESTS)} />

                <Button variant="outlined" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                  Save as Draft
                </Button>

                <Button variant="contained" style={{ marginLeft: '20px' }}
                  startIcon={<Iconify icon="eva:save-outline" />}
                  onClick={() => {
                    confirmAlert({
                      title: 'Confirm for Final Submit',
                      message: 'On Submit the Contests will be created in the background. Do you want to continue?',
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            setFieldValue('status', 2);
                            handleSubmit();
                          },
                        },
                        {
                          label: 'No',
                        },
                      ],
                    });
                  }}>
                  Final Submit
                </Button>
              </div>
            </Stack>

            <Card >
              <CardHeader title="Contest Details" />
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <label style={labelStyle}>Contest Type</label>
                    <Select
                      options={contestTypes}
                      placeholder="Select Contest Type"
                      styles={customStyles}
                      value={contestTypes.filter((item) => item?.value === values?.contestType)}
                      defaultValue={values?.contestType}
                      onChange={(data) => {
                        setFieldValue('contestType', data?.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label style={labelStyle}>Select Partner</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Partner"
                      styles={customStyles}
                      loadOptions={loadPartners}
                      value={values?.partnerId}
                      onChange={(data) => {
                        setFieldValue('partnerId', data);
                      }}
                    />
                    {Boolean(touched.partnerId && errors.partnerId) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.partnerId}
                      </Typography>
                    )}
                  </Grid>

                  {(values?.contestType === 2 || values?.contestType === 3) && (
                    <>
                      <Grid item xs={12} md={3} mt={3}>
                        <TextField
                          fullWidth
                          label="Visible Radius (In Miles)"
                          {...getFieldProps('radius')}
                          error={Boolean(touched.radius && errors.radius)}
                          helperText={touched.radius && errors.radius}
                        />
                      </Grid>
                    </>
                  )}
                  {(values?.contestType === 2 || values?.contestType === 3) && (
                    <>
                      <Grid container paddingLeft={2} paddingRight={2} paddingTop={2}>
                        <FieldArray
                          name="locations"
                          render={(arrayHelpers) => (
                            <>
                              {(values?.locations || [])?.map((item, index) => (
                                <Fragment key={item?.id}>
                                  <Grid key={item?.place_id} item xs={8} md={10} paddingBottom={1}>
                                    <Autocomplete
                                      key={item?.place_id}
                                      apiKey={GoogleMapsApiKey}
                                      onPlaceSelected={(place) => {
                                        setFieldValue(`locations.${index}`, place);
                                      }}
                                      defaultValue={
                                        values?.locations?.[index]?.formatted_address ||
                                        values?.locations?.[index]?.address
                                      }
                                      style={placePickerStyles}
                                      options={{ fields: ['ALL'], types: ['establishment', 'geocode'] }}
                                    />
                                    {!locationError &&
                                      Boolean(touched?.locations?.[index] && errors?.locations?.[index]) && (
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          gutterBottom
                                          color={'red'}
                                          paddingTop={1}
                                          paddingLeft={2}
                                        >
                                          {errors?.locations?.[index]}
                                        </Typography>
                                      )}
                                  </Grid>

                                  {values?.locations?.length !== 1 && (
                                    <IconButton
                                      size="large"
                                      sx={{ ml: 2, color: colors.red[500] }}
                                      onClick={() => {
                                        removeLocationHandler(values?.locations?.[index]?.id);
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <Iconify icon="eva:minus-square-fill" />
                                    </IconButton>
                                  )}
                                  <Grid item xs={1} md={1}>
                                    <IconButton
                                      size="large"
                                      sx={{ ml: 2, color: 'primary.main' }}
                                      onClick={() => {
                                        arrayHelpers.push('');
                                      }}
                                    >
                                      <Iconify icon="eva:plus-square-fill" />
                                    </IconButton>
                                  </Grid>
                                </Fragment>
                              ))}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid container paddingLeft={2} paddingRight={2}>
                        {locationError ? (
                          <div className="errorText">Please remove duplicate locations</div>
                        ) : (
                          <div className="trasparentText">Trasparent Text</div>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Contest Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Week Name"
                      {...getFieldProps('weekName')}
                      error={Boolean(touched.weekName && errors.weekName)}
                      helperText={touched.weekName && errors.weekName}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={3}>
                    <Select
                      isClearable
                      options={QUESTION_TO_SHOW_CONSTANT}
                      placeholder="Select Question To Show"
                      styles={customStyles}
                      value={QUESTION_TO_SHOW_CONSTANT.filter((item) => item?.value === values?.questionsToShow)}
                      defaultValue={values?.questionsToShow}
                      onChange={(data) => {
                        if (data === null) {
                          setFieldValue('questionsToShow', null);
                        } else {
                          setFieldValue('questionsToShow', data?.value);
                        }
                      }}
                    />
                  </Grid> */}

                  <Grid item xs={12} md={2}>
                    <FormControlLabel
                      control={<Switch />}
                      label="Is Age needed?"
                      checked={values?.isAgeNeeded}
                      onChange={(e) => setFieldValue('isAgeNeeded', e?.target?.checked)}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Minimum Age"
                      {...getFieldProps('minimumAge')}
                      error={Boolean(touched.minimumAge && errors.minimumAge)}
                      helperText={touched.minimumAge && errors.minimumAge}
                      disabled={!values?.isAgeNeeded}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Age Description"
                      {...getFieldProps('ageDescription')}
                      helperText={touched.ageDescription && errors.ageDescription}
                      disabled={!values?.isAgeNeeded}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Contest Entry Points"
                      {...getFieldProps('contestEntryPoints')}
                      helperText={touched.contestEntryPoints && errors.contestEntryPoints}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={labelStyle}>Select Away Team</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Away Team"
                      styles={customStyles}
                      loadOptions={loadAwayTeamData}
                      name="awayTeamId"
                      value={values?.awayTeamId}
                      onChange={(data) => {
                        setFieldValue('awayTeamId', data);
                      }}
                    />

                    {Boolean(touched.awayTeamId && errors.awayTeamId) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.awayTeamId}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={labelStyle}>Select Home Team</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Home Team"
                      styles={customStyles}
                      loadOptions={loadHomeTeamData}
                      name="homeTeamId"
                      value={values?.homeTeamId}
                      onChange={(data) => {
                        setFieldValue('homeTeamId', data);
                      }}
                    />

                    {Boolean(touched.homeTeamId && errors.homeTeamId) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.homeTeamId}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values?.startDateTime}
                        label="Starting date"
                        onChange={(date) => setFieldValue('startDateTime', new Date(date))}
                        // disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.startDateTime && errors.startDateTime)}
                            helperText={touched.startDateTime && errors.startDateTime}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Select
                      isClearable
                      options={BULK_START_TYPE}
                      placeholder="Select Start Type"
                      styles={customStyles}
                      value={BULK_START_TYPE.filter((item) => item?.value === values?.startType)}
                      defaultValue={values?.startType}
                      onChange={(data) => {
                        if (data === null) {
                          setFieldValue('startType', null);
                        } else {
                          setFieldValue('startType', data?.value);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Max Days to End"
                      {...getFieldProps('maxDays')}
                      error={Boolean(touched.maxDays && errors.maxDays)}
                      helperText={touched.maxDays && errors.maxDays}
                    />
                  </Grid>

                  <Grid container padding={2}>
                    <FieldArray
                      name="overview"
                      render={(arrayHelpers) => (
                        <>
                          {values?.overview?.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={8} md={10}>
                                <TextField
                                  fullWidth
                                  name={`overview.${index}`}
                                  label={index === 0 ? 'Overview' : ''}
                                  placeholder={index === 0 ? '1. Overview' : `${index + 1}. Overview`}
                                  {...getFieldProps(`overview.${index}`)}
                                  error={Boolean(touched.overview?.[index] && errors.overview?.[index])}
                                  helperText={touched.overview?.[index] && errors.overview?.[index]}
                                />
                              </Grid>
                              {values?.overview?.length !== 1 && (
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: colors.red[500] }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Iconify icon="eva:minus-square-fill" />
                                </IconButton>
                              )}
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => arrayHelpers.insert(index + 1, '')}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid container padding={2}>
                    <FieldArray
                      name="howItWorks"
                      render={(arrayHelpers) => (
                        <>
                          {values.howItWorks?.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={8} md={10}>
                                <TextField
                                  fullWidth
                                  name={`howItWorks.${index}`}
                                  label={index === 0 ? 'How it Works' : ''}
                                  placeholder={index === 0 ? '1. How it Works' : `${index + 1}. How it Works`}
                                  {...getFieldProps(`howItWorks.${index}`)}
                                  error={Boolean(touched.howItWorks?.[index] && errors.howItWorks?.[index])}
                                  helperText={touched.howItWorks?.[index] && errors.howItWorks?.[index]}
                                />
                              </Grid>
                              {values?.howItWorks?.length !== 1 && (
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: colors.red[500] }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Iconify icon="eva:minus-square-fill" />
                                </IconButton>
                              )}
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => arrayHelpers.insert(index + 1, '')}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Terms Link"
                      {...getFieldProps('termsLink')}
                      error={Boolean(touched.termsLink && errors.termsLink)}
                      helperText={touched.termsLink && errors.termsLink}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Rules Link"
                      {...getFieldProps('rulesLink')}
                      error={Boolean(touched.rulesLink && errors.rulesLink)}
                      helperText={touched.rulesLink && errors.rulesLink}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Email Subject" {...getFieldProps('emailSubject')} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Email Body Content" {...getFieldProps('emailBodyContent')} />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                    <FormControlLabel control={
                      <Switch
                        checked={values?.sendNotifications}
                        onChange={(date) => {
                          toggleSwitch1();
                          setFieldValue('sendNotifications', date?.target?.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                      label="Send Notifications [All]"
                    />

                    <FormControlLabel style={{ marginLeft: '40px' }}
                      control={
                        <Switch
                          checked={values?.sendContestUserNotifications}
                          onChange={(date) => {
                            toggleSwitch2();
                            setFieldValue('sendContestUserNotifications', date?.target?.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Send Notifications [Partner]"
                    />

                    <FormControlLabel style={{ marginLeft: '40px' }}
                      control={
                        <Switch
                          checked={values?.postOnTwitter}
                          onChange={(date) => {
                            setFieldValue('postOnTwitter', date?.target?.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Post On Twitter"
                    />

                  </Grid>

                  <Grid item xs={12} md={12}>

                    {values?.sendNotifications && (
                      <>
                        <label style={{ fontSize: '12px' }}>Exclude Partners from All Notifications</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          isMulti
                          placeholder="Select Partners to Exclude"
                          styles={customStyles}
                          loadOptions={loadPartners}
                          value={values?.excludePartnersFromAllNotification}
                          onChange={(data) => {
                            setFieldValue('excludePartnersFromAllNotification', data || []);
                          }}
                        />
                      </>
                    )}
                  </Grid>

                  {IMAGE_UPLOAD_FIELDS?.map((item) => (
                    <Grid item xs={12} md={4} key={item.fieldName}>
                      <UploadImage
                        title={item.title}
                        fieldName={item.fieldName}
                        stateImage={values[item.fieldName] && values[item.fieldName]?.size ? true : false}
                        image={values[item.fieldName] && values[item.fieldName]?.size ? URL.createObjectURL(values[item.fieldName]) : values[item.fieldName]}
                        fileSizeExceeded={errors?.[item.fieldName]}
                        onChange={(e) => handleImageChange(e, item.fieldName)}
                        uploadImage={() => handleAllImages(item)}
                        removeImage={() => setFieldValue(item.fieldName, null)}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                  ))}

                </Grid>
              </Box>
            </Card>

            <Card style={{ marginTop: '20px' }}>
              <CardHeader title="Quiz Selection Settings" />
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2} style={{ paddingTop: '0px' }}>
                  <>
                    {QUESTION_FORM_FIELDS.map((field, fieldIndex) => {
                      return (
                        <Grid item xs={12} md={field.gridSize} key={fieldIndex}>
                          <TextField
                            fullWidth
                            key={fieldIndex}
                            label={field.label}
                            type={field.type}
                            name={field.identifier}
                            value={field.value}
                            placeholder={field?.placeholder}
                            defaultValue={values?.[field.identifier]}
                            {...getFieldProps(field.identifier)}
                          // onChange={(e) => {
                          //   setQuestionCriteria({ ...questionCriteria, [field.identifier]: e.target.value });
                          // }}
                          />

                        </Grid>
                      )
                    })}
                  </>
                </Grid>
              </Box>
            </Card>


            <Card sx={{ marginTop: '20px', minHeight: '550px' }}>
              <CardHeader title="Reward Rules Settings" />
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Total Number of Positions"
                      {...getFieldProps('numberOfPositions')}
                      error={Boolean(touched.numberOfPositions && errors.numberOfPositions)}
                      helperText={touched.numberOfPositions && errors.numberOfPositions}
                    />
                  </Grid>

                  <Grid container padding={2} spacing={2}>
                    <FieldArray
                      name="rewardData"
                      render={(arrayHelpers) => (
                        <>
                          {values?.rewardData?.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={6} md={4}>
                                <Select
                                  name={`rewardData.${index}.positions`}
                                  isMulti
                                  placeholder="Select Positions"
                                  options={[{ value: positionOptions, label: 'All' }, ...positionOptions]}
                                  styles={customStyles}
                                  value={values?.rewardData?.[index]?.positions}
                                  onChange={(data) => {
                                    data?.find((option) => option?.label === 'All')
                                      ? setFieldValue(`rewardData.${index}.positions`, positionOptions)
                                      : setFieldValue(`rewardData.${index}.positions`, data);
                                  }}
                                />
                                {errors?.rewardData?.[index]?.positions && touched?.rewardData?.[index]?.positions && (
                                  <div className="field-error">{errors?.rewardData?.[index]?.positions}</div>
                                )}
                              </Grid>
                              <Grid item xs={6} md={8}>
                                <AsyncSelect
                                  name={`rewardData.${index}.rewardId`}
                                  cacheOptions
                                  defaultOptions
                                  placeholder="Select Reward Title/Short Description"
                                  loadOptions={loadRewardTypes}
                                  value={values?.rewardData[index]?.rewardId}
                                  styles={customStyles}
                                  isClearable
                                  onChange={(data) => {
                                    setFieldValue(`rewardData.${index}.rewardId`, data);
                                  }}
                                />
                                {errors?.rewardData?.[index]?.rewardId && touched?.rewardData?.[index]?.rewardId && (
                                  <div className="field-error">{errors?.rewardData?.[index]?.rewardId}</div>
                                )}
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField
                                  fullWidth
                                  value={values?.rewardData[index]?.expireDays}
                                  type="number"
                                  name={`rewardData.${index}.expireDays`}
                                  label={'Reward Expiry Days'}
                                  placeholder={'Expiry Days'}
                                  onChange={(e) =>
                                    setFieldValue(`rewardData.${index}.expireDays`, parseInt(e.target.value))
                                  }
                                  error={Boolean(
                                    touched?.rewardData?.[index]?.expireDays && errors?.rewardData?.[index]?.expireDays
                                  )}
                                  helperText={
                                    touched?.rewardData?.[index]?.expireDays && errors?.rewardData?.[index]?.expireDays
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField
                                  fullWidth
                                  value={values?.rewardData[index]?.redeemTime}
                                  type="number"
                                  name={`rewardData.${index}.redeemTime`}
                                  label={index === 0 ? 'Redeem Time (in Mins)' : ''}
                                  placeholder={
                                    index === 0 ? '1. Redeem Time (in Mins)' : `${index + 1}. Redeem Time (in Mins)`
                                  }
                                  onChange={(e) =>
                                    setFieldValue(`rewardData.${index}.redeemTime`, parseInt(e.target.value))
                                  }
                                  error={Boolean(
                                    touched?.rewardData?.[index]?.redeemTime && errors?.rewardData?.[index]?.redeemTime
                                  )}
                                  helperText={
                                    touched?.rewardData?.[index]?.redeemTime && errors?.rewardData?.[index]?.redeemTime
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField
                                  fullWidth
                                  value={values?.rewardData[index]?.winningPoints}
                                  type="number"
                                  name={`rewardData.${index}.winningPoints`}
                                  label={index === 0 ? 'Winning Points' : ''}
                                  placeholder={index === 0 ? '1. Winning Points ' : `${index + 1}. Winning Points`}
                                  onChange={(e) =>
                                    setFieldValue(`rewardData.${index}.winningPoints`, parseInt(e.target.value))
                                  }
                                  error={Boolean(
                                    touched?.rewardData?.[index]?.winningPoints && errors?.rewardData?.[index]?.winningPoints
                                  )}
                                  helperText={
                                    touched?.rewardData?.[index]?.winningPoints && errors?.rewardData?.[index]?.winningPoints
                                  }
                                />
                              </Grid>

                              <Grid item xs={2} md={1}>
                                {values?.rewardData?.length !== 1 && (
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: colors.red[500] }}
                                    onClick={() => {
                                      return arrayHelpers.remove(index);
                                    }}
                                  >
                                    <Iconify icon="eva:minus-square-fill" />
                                  </IconButton>
                                )}
                                <Grid item xs={2} md={4}>
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: 'primary.main' }}
                                    onClick={() => {
                                      arrayHelpers.insert(index + 1, emptyRewardData);
                                    }}
                                  >
                                    <Iconify icon="eva:plus-square-fill" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

          </Form>
        </FormikProvider>
      </Container>
    </Page >
  );
};

export default AddEditContestBulkCreate;
